<template>
  <v-container>
    <!-- BOC:[breadcrumbs] -->
    <ABreadcrumb :items="breadcrumbs"></ABreadcrumb>
    <!-- EOC -->
    <v-card class="mx-auto pa-3" max-width="800" outlined min-height="400">
      <v-card-title>Get Help</v-card-title>
      <div class="d-flex align-center justify-space-around">
        <div class="form-content text-center">
          <div>
            <v-icon size="150"> mdi-hammer-wrench </v-icon>
          </div>
          <div class="text-h5 pt-1 font-weight-black">Oops!</div>
          <div class="pt-2">Page under the construction.</div>
          <div class="text-center pt-3">
            <v-btn width="75%" rounded color="primary" :to="{ name: 'PageMainHome' }" dark> Go to Home </v-btn>
          </div>
        </div>
      </div>
    </v-card>
  </v-container>
</template>
    
    <script>
import { mapState } from "vuex";
export default {
  components: {
    //
  },
  computed: mapState({
    //
  }),
  props: [
    //
  ],
  data: () => ({
     //BOC:[breadcrumbs]
     breadcrumbs: [],
      //EOC
  }),
  created() {
      //BOC:[breadcrumbs]
      this.breadcrumbs.push({
        text:this.$t("route.PageMainHome"),
        to:{name:'PageMainHome'},
        exact:true,
      })
      this.breadcrumbs.push({
        text:'Help',
        to:{name:'PageMainHelp'},
        exact:true,
      })
      //EOC
  },
  mounted() {
    
  },
  methods: {
    clickMenu(item) {
      this.$router.push({ name: item.route });
    },
  },
};
</script>
<style scoped>
.menu-item {
  border: 1px solid black;
  margin: 3px;
}
.form-content {
  width: 300px !important;
}
</style>